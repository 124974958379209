import detectEthereumProvider from '@metamask/detect-provider';
import web3Provider from 'services/web3'
import Web3 from 'web3';
import PropTypes from 'prop-types'

/**
 * It's a function that takes in 3 parameters, and returns a promise that resolves to a value. 
 * 
 * The 3 parameters are: 
 * 
 * 1. setMsg: a function that takes in a string and sets the state of the component to that string. 
 * 2. setData: a function that takes in a value and sets the state of the component to that value. 
 * 3. setIsLoading: a function that takes in a boolean and sets the state of the component to that
 * boolean. 
 * 
 * The promise resolves to a value that is the result of calling the web3Provider function. 
 * 
 * The web3Provider function is not shown in the code you posted, but I'm guessing it returns a promise
 * that resolves to a value. 
 * 
 * The metamask function is called in the componentDidMount function of the component. 
 * 
 * The
 * @param setMsg - is a function that sets the message to be displayed to the user
 * @param setData - this is a function that sets the data in the state
 * @param setIsLoading - a function that sets the loading state of the app
 */
const metamask = async (setMsg, setData, setIsLoading) => {
	try {
		const provider = await detectEthereumProvider();

		if (!provider) {
			alert('Metamask is not installed');
			return;
		}

		try {
			await provider.request({ method: 'eth_requestAccounts' });
		} catch (error) {
			if (error.code === -32002) {
				setMsg("Please open your Wallet in your browser");
			}
			throw error;
		}

		localStorage.setItem('isMetamask', true);
		localStorage.setItem('wallet', true);

		const setupProvider = async () => {
			try {
				window.localStorage.removeItem('signature');
				window.localStorage.removeItem('message');
				setIsLoading(true);
				const result = await web3Provider(provider);
				if (!result || (typeof result === 'object' && Object.keys(result?.user).length === 0 )) {
					localStorage.clear();
					setData(null)
				} else {
					setData(result);
				}
			} catch (error) {
				console.error('Error in setupProvider:', error);
			} finally {
				setIsLoading(false);
			}
		};

		// Handle account changes
		provider.on('accountsChanged', async () => {
			await setupProvider();
		});

		provider.removeListener('accountsChanged', async () => {
			localStorage.clear();
			window.location.reload();
		});

		// Handle chain changes
		provider.on('chainChanged', async () => {
			await setupProvider();
		});

		provider.removeListener('chainChanged', async () => {
			window.location.reload();
		});

		return await web3Provider(provider);
	} catch (error) {
		console.error('Metamask error:', error);
		return null;
	}
};


const switchEthereumChain = async (network) => {
    if (window?.ethereum) {
        try {
            if (network === 0) return;
            console.log('network ::', network)
            if (process.env.REACT_APP_NETWORK == network) {
                console.log('hex ::', Web3.utils.toHex(process.env.REACT_APP_NETWORK))
                let provider = {
                    chainId: Web3.utils.toHex(process.env.REACT_APP_NETWORK),
                    chainName: process.env.REACT_APP_NETWORK_NAME,
                    nativeCurrency: {
                        name: 'ethereum',
                        symbol: 'ethereum',
                        decimals: 18,
                    },
                    rpcUrls: [process.env.REACT_APP_RPC],
                    blockExplorerUrls: [process.env.REACT_APP_SCAN]
                }
                await window?.ethereum.request({ method: "wallet_addEthereumChain", params: [provider] })
                await window?.ethereum?.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: Web3.utils.toHex(network) }],
                });
            } else {
                await window?.ethereum?.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: Web3.utils.toHex(network) }],
                });
            }
        } catch (error) {
            console.log('Error ::', error);
            //alert('Error to change network')
        }
    }
}

metamask.propTypes = {
    setMsg: PropTypes.func
}
export { switchEthereumChain }
export default metamask